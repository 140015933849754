<template>

  <!-- Billing Dialog Free Chosen -->
  <div class="ad-billing-dialog-free-chosen">

    <!-- Body -->
    <div class="ad-billing-dialog-free-chosen__body">

      <!-- Plan Icon -->
      <div class="ad-billing-dialog-free-chosen__icon">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M29.44 29.706a4.72 4.72 0 004.716-4.723v-12.26A4.718 4.718 0 0029.44 8H10.56a4.72 4.72 0 00-4.716 4.723v12.26a4.718 4.718 0 004.716 4.723h4.198c.52 0 1.222.314 1.57.704l3.168 3.558c.346.389.91.39 1.256 0l3.17-3.558c.345-.389 1.051-.704 1.569-.704h3.949z" fill="#005AEE" fill-opacity=".24"/><path d="M29.44 29.706a4.72 4.72 0 004.716-4.723v-12.26A4.718 4.718 0 0029.44 8H10.56a4.72 4.72 0 00-4.716 4.723v12.26a4.718 4.718 0 004.716 4.723h4.198c.52 0 1.222.314 1.57.704l3.168 3.558c.346.389.91.39 1.256 0l3.17-3.558c.345-.389 1.051-.704 1.569-.704h3.949z" fill="url(#paint0_linear)"/><path d="M27.55 17.63c0-2.191-1.787-3.967-3.992-3.967A3.99 3.99 0 0020 15.838a3.99 3.99 0 00-3.558-2.176c-2.205 0-3.992 1.776-3.992 3.967 0 4.145 6.697 8.684 7.55 8.684.854 0 7.55-4.52 7.55-8.684z" fill="#005AEE"/><path d="M27.55 17.63c0-2.191-1.787-3.967-3.992-3.967A3.99 3.99 0 0020 15.838a3.99 3.99 0 00-3.558-2.176c-2.205 0-3.992 1.776-3.992 3.967 0 4.145 6.697 8.684 7.55 8.684.854 0 7.55-4.52 7.55-8.684z" fill="url(#paint1_linear)"/><defs><linearGradient id="paint0_linear" x1="20" y1="8" x2="20" y2="34.26" gradientUnits="userSpaceOnUse"><stop stop-color="#fff" stop-opacity=".65"/><stop offset="1" stop-color="#fff" stop-opacity="0"/></linearGradient><linearGradient id="paint1_linear" x1="20" y1="13.662" x2="20" y2="26.314" gradientUnits="userSpaceOnUse"><stop stop-color="#fff" stop-opacity=".34"/><stop offset="1" stop-color="#fff" stop-opacity="0"/></linearGradient></defs></svg>
      </div>
      <!-- /Plan Icon -->

      <!-- Title -->
      <div class="ad-billing-dialog-free-chosen__title">
        <span>
          {{ title }}
        </span>
      </div>
      <!-- /Title -->

      <!-- Includes -->
      <div class="ad-billing-dialog-free-chosen__includes">
        <div
          v-for="include in getIncludes(selectedPlan)"
          :key="include"
          class="ad-billing-dialog-free-chosen__includes__item"
        >
          <template v-if="include">
            <adm-icon icon="check-circle" size="small" />
            <span>
              {{ include }}
            </span>
          </template>
        </div>
      </div>
      <!-- /Includes -->

      <!-- Downgrade Alert -->
      <adm-alert
        v-if="downgradingToFree"
        class="ad-billing-dialog-free-chosen__alert"
        type="default"
        :description="$t('downgrade_free_plan_alert')"
      />
      <!-- /Downgrade Alert -->

      <!-- Go To Trafft -->
      <div class="ad-billing-dialog-free-chosen__close">
        <adm-button @click="onClose">
          {{ $transWithBusinessName($t('go_to_trafft')) }}
        </adm-button>
      </div>
      <!-- /Go To Trafft -->

    </div>
    <!-- /Body -->

    <!-- Footer - TODO - Move to component (duplicate) -->
    <div class="ad-billing-dialog-free-chosen__footer">

      <!-- Trafft -->
      <span class="ad-billing-dialog-free-chosen__footer__trafft">
        © Trafft {{ $moment().format('Y') }}
      </span>
      <!-- /Trafft -->

      <!-- Links -->
      <span class="ad-billing-dialog-free-chosen__footer__links">
        <a href="https://trafft.com/terms-of-service/" target="_blank">
          {{ $t('terms_of_service') }}
        </a>
        <span>
          -
        </span>
        <a @click="openBeacon()">
          {{ $t('contact_us') }}
        </a>
      </span>
      <!-- /Links -->

    </div>
    <!-- /Footer -->

  </div>
  <!-- /Billing Dialog Free Chosen -->

</template>

<script>
import AdmAlert from '@/views/_components/alert/AdmAlert'
import AdmButton from '@/views/_components/button/AdmButton'
import AdmIcon from '@/views/_components/icon/AdmIcon'
import mixinPlans from '@/mixins/plans/plans'
import { mapState } from 'vuex'
import Gleap from 'gleap'

export default {
  name: 'BillingDialogFreeChosen',

  components: {
    AdmAlert,
    AdmButton,
    AdmIcon,
  },

  mixins: [
    mixinPlans
  ],

  computed: {
    ...mapState({
      downgradingToFree: state => state.billing.downgradingToFree,
      selectedPlan: state => state.billing.selectedPlan,
      tenantInfo: state => state.billing.tenantInfo
    }),

    title () {
      if (this.downgradingToFree) {
        return this.$transWithBusinessName(this.$t('using_free_plan_in_the_future'))
      }

      return this.$transWithBusinessName(this.$t('using_free_plan'))
    }
  },

  methods: {
    onClose () {
      this.$store.commit('billing/setVisibleDialogBilling', false)
      this.$emit('change-step', 'plans')
      window.location.reload();
    },

    openBeacon () {
      if (Gleap.isOpened()) {
        Gleap.close()

        return
      }
      Gleap.startConversation(true)
    }
  }
}
</script>

<style lang="scss" scoped>
// Billing Dialog Free Chosen
.ad-billing-dialog-free-chosen {
  width: 368px;
  background: $adm-white;
  margin: 1.25rem auto 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  // Body
  &__body {
    padding: 0 24px;
  }

  // Icon
  &__icon {
    display: flex;
    justify-content: center;
    padding-top: 2rem;

    svg {
      width: 100px;
      height: 100px;
    }
  }

  // Title
  &__title {
    margin-top: 1rem;
    padding-bottom: 1.25rem;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    text-align: center;
    border-bottom: 1px solid $shade-300;
  }

  // Includes
  &__includes {
    padding-top: 1.25rem;
    align-items: center;

    &__item {
      display: flex;
      align-items: center;
      margin-bottom: 0.25rem;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        font-weight: normal;
        margin-left: 0.25rem;
        font-size: 13px;
      }

      svg {
        flex-shrink: 0;
      }
    }
  }

  // Alert
  &__alert {
    margin-top: 0.75rem;
  }

  // Close (Go to Trafft)
  &__close {
    margin: 1.25rem 0 1.5rem;

    button {
      width: 100%;
    }
  }

  // Footer
  &__footer {
    border-top: 1px solid $shade-300;
    padding: 18px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    // Trafft
    &__trafft {
      font-weight: normal;
      font-size: 14px;
      color: $shade-500;
      margin-bottom: 0.5rem;
    }

    // Links
    &__links {
      font-weight: normal;
      display: flex;

      span {
        text-align: center;
        color: $shade-500;
        margin: 0 0.75rem;
      }
    }
  }
}
</style>
