<template>

  <!-- Main Header -->
  <div
    class="main-header"
    :class="[{'is-main-navigation-bar-expanded': $store.state.menu.isExpanded}]"
  >

    <!-- Logo -->
    <router-link
      v-if="$store.getters['settings/isLogoSquareVisible']"
      :to="{ name: 'home' }"
      class="main-header__logo tablet-visible"
    >
      <img
        v-if="$store.getters['settings/isAgency']"
        :src="$store.state.settings.agency.agencyLogoSquareUrl"
        alt="logo"
      >
      <img v-if="!$store.getters['settings/isAgency']" alt="logo" src="@/assets/img/logo/logo-square.svg">
    </router-link>
    <!-- /Logo -->

    <div class="main-header__left">
      <!-- Booking Website -->
      <div class="flex">
        <div class="main-header__booking-website">

          <!-- Booking Website Button -->
          <guide-button-wrapper
            :title="$t('open_booking_website')"
            :description="$t('open_booking_website_desc')"
            :is-opened="isOpenWebsitePopoverOpened"
            placement="bottom"
            :width="392"
            :is-close-presented="false"
          >
            <adm-button-group
              class="main-header__booking-website__group"
            >
              <adm-button
                :class="{
                  'active-popover-border': isOpenWebsitePopoverOpened
                }"
                class="main-header__booking-website__button"
                size="medium"
                color="blue"
                icon-start="globe-outline"
                group-position="left"
                @click="goToWebsite"
              >
                {{ $t('booking_website') }}
              </adm-button>
              <adm-button
                v-popover:popover
                class="main-header__booking-website__dropdown"
                size="medium"
                color="blue"
                group-position="right"
                icon-start="arrow-down"
                @click="handleBookingWebsitePopover"
              />
            </adm-button-group>
          </guide-button-wrapper>
          <!-- /Booking Website Button -->

          <!-- Booking Website Popover -->
          <el-popover
            ref="popover"
            popper-class="main-header__booking-website__popover"
            effect="light"
            placement="bottom-end"
            :visible-arrow="false"
            trigger="click"
            @show="bookingWebsitePopoverActive = true"
            @hide="bookingWebsitePopoverActive = false"
          >
            <div>

              <!-- Booking Website URL Input -->
              <el-form>
                <adm-form-item class="mb-12" :label="$t('booking_website_url')">
                  <adm-input id="main-navigation-website-url" :value="getBookingUrl()" :readonly="true" />
                </adm-form-item>
              </el-form>
              <!-- /Booking Website URL Input -->

              <!-- Open Booking Website URL -->
              <div class="main-header__booking-website__popover__buttons">
                <adm-button
                  icon-start="copy"
                  size="small"
                  type="borderless"
                  color="blue"
                  @click="copyBookingUrl"
                >
                  {{ $t('copy_website_link') }}
                </adm-button>
              </div>
              <!-- /Open Booking Website URL -->

            </div>
          </el-popover>
          <!-- /Booking Website Popover -->

        </div>
      </div>
      <!-- /Booking Website -->

      <!-- ShareLinkButton Button -->
      <share-link-button @click="toggleShareLinkModal" />
      <!-- /ShareLinkButton Button -->
    </div>

    <!-- Icon Buttons -->
    <div
      class="main-header__icon-buttons"
      :class="{
        'main-header__icon-buttons--agency' : $store.getters['settings/isAgency'],
      }"
    >

      <!-- Booking Website Button -->
      <a :href="getBookingUrl()">
        <adm-button
          class="main-header__icon-buttons__booking-website"
          icon-start="globe-outline"
          size="medium"
          type="borderless"
        />
      </a>
      <!-- /Booking Website Button -->

      <!-- ShareLinkButton Button -->
      <adm-button
        class="main-header__icon-buttons__share-link"
        icon-start="share"
        size="medium"
        type="borderless"
        @click="toggleShareLinkModal"
      />
      <!-- /ShareLinkButton Button -->

      <!-- Help -->
      <adm-dropdown
        v-if="!$store.getters['settings/isAgency']"
        class="main-header__icon-buttons__help"
        @command="handleHelpCommand"
      >
        <adm-button icon-start="question-mark-circle-outline" size="medium" color="dark" type="borderless" />
        <adm-dropdown-menu>
          <template #default>
            <adm-dropdown-item command="support" icon="message-square">
              {{ $t('support') }}
            </adm-dropdown-item>
            <adm-dropdown-item
              v-if="!$store.getters['settings/isAgency'] && $screen.aboveMobile"
              command="watch-video-tutorials"
              icon="videotutorial"
            >
              {{ $t('watch_video_tutorial') }}
            </adm-dropdown-item>
            <adm-dropdown-item command="view-documentation" icon="book-outline">
              {{ $t('read_how_it_works') }}
            </adm-dropdown-item>
            <adm-dropdown-item command="suggest-feature" icon="bulbtip">
              {{ $t('suggest_feature') }}
            </adm-dropdown-item>
            <div class="main-header__icon-buttons__help__version">
              <div class="main-header__icon-buttons__help__version__text">
                {{ getVersionText() }}
              </div>
            </div>
          </template>
        </adm-dropdown-menu>
      </adm-dropdown>
      <!-- /Help -->

      <!-- Activity -->
      <release-notes-wrapper v-if="!$store.getters['settings/isAgency']" />
      <!-- /Activity -->

    </div>
    <!-- /Icon Buttons -->

    <!-- Activity -->
    <release-notes-wrapper v-if="$store.getters['settings/isAgency']" class="mr-24" />
    <!-- /Activity -->

    <!-- Account -->
    <adm-dropdown v-if="$cookies.get('impersonatePage') !== 'agency'" class="main-header__account" :tabindex="0" @command="handleAccountCommand">

      <!-- Account Dropdown Slot -->
      <div class="main-header__account__slot">

        <!-- Avatar -->
        <adm-avatar
          :is-human="true"
          :name="`${ $store.state.user.firstName } ${ $store.state.user.lastName }`"
          :size="36"
          :src="$store.state.user.mediaUrl"
        />
        <!-- /Avatar -->

        <!-- Name -->
        <span class="main-header__account__slot__name tablet-hidden">
          {{ $store.state.user.firstName + ' ' + $store.state.user.lastName }}
        </span>
        <!-- /Name -->

        <!-- Arrow -->
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'main-header__account__slot__arrow tablet-hidden'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="customize-fill" d="M12 15a.75.75 0 01-.48-.172l-4.5-3.75a.75.75 0 11.96-1.155l4.02 3.36 4.02-3.24a.75.75 0 011.058.112.75.75 0 01-.105 1.095l-4.5 3.623A.75.75 0 0112 15z" fill="#005AEE"/></svg>
        <!-- /Arrow -->

      </div>
      <!-- /Account Dropdown Slot -->

      <!-- Account Dropdown -->
      <adm-dropdown-menu slot="dropdown">
        <adm-dropdown-item command="account-settings" icon="user">
          {{ $t('account_settings') }}
        </adm-dropdown-item>

        <template v-if="!isPwa">
          <adm-dropdown-item
            v-if="hasPermission('edit_billing_plan')"
            command="billing-plan"
            icon="billing"
          >
            {{ $t('billing') }}
          </adm-dropdown-item>

          <adm-dropdown-item
            v-if="showUpgradePlan"
            command="upgrade-plan"
            icon="arrow-circle-up"
            blue
          >
            {{ $t('upgrade_plan') }}
          </adm-dropdown-item>
        </template>

        <adm-dropdown-item
          v-if="!$store.getters['settings/isAgency']"
          command="refer-friend"
          icon="heart"
        >
          {{ $t('refer_friend') }}
        </adm-dropdown-item>

        <adm-dropdown-item
          v-if="!$store.getters['settings/isAgency']"
          command="rate-trafft"
          icon="star-outlined"
        >
          {{ $t('rate_trafft') }}
        </adm-dropdown-item>

        <adm-dropdown-item command="logout" icon="logout" :divided="!!$screen.aboveTablet">
          {{ $t('log_out') }}
        </adm-dropdown-item>
      </adm-dropdown-menu>
      <!-- /Account Dropdown -->

    </adm-dropdown>
    <!-- /Account -->

    <!-- Tutorial Video Modal -->
    <tutorial-video-modal
      v-if="isTutorialVideoVisible"
      link="https://www.youtube.com/embed/DS29VMhKOOw"
      @close="isTutorialVideoVisible = false"
    />
    <!-- /Tutorial Video Modal -->

    <!-- Refer A Friend Modal -->
    <refer-friend-dialog
      :visible="showReferFriendDialog"
      @close="showReferFriendDialog = false"
    />
    <!-- /Refer A Friend Modal -->

    <!-- Rate Trafft Dialog -->
    <rate-trafft-dialog
      :visible="showRateTrafftDialog"
      @close="showRateTrafftDialog = false"
    />
    <!-- /Rate Trafft Dialog -->

  </div>
  <!-- /Main Header -->

</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton'
import AdmButtonGroup from '@/views/_components/button/AdmButtonGroup'
import AdmDropdown from '@/views/_components/dropdown/AdmDropdown'
import AdmDropdownItem from '@/views/_components/dropdown/AdmDropdownItem'
import AdmDropdownMenu from '@/views/_components/dropdown/AdmDropdownMenu'
import AdmFormItem from '@/views/_components/form/AdmFormItem'
import AdmInput from '@/views/_components/input/AdmInput'
import GuideButtonWrapper from '@/views/Guide/GuideButtonWrapper'
import ReleaseNotesWrapper from '@/views/Dashboard/MainHeader/ReleaseNotes/ReleaseNotesWrapper'
import mixinRouter from '@/mixins/common/router'
import mixinSecurity from '@/mixins/security/security'
import mixinLogout from '@/mixins/logout/logout'
import { copyContent, getBookingUrl } from '@/utils/url'
import { isPwa, isPwaOnAppleDevices } from '@/utils/isPwa'
import { mapState } from 'vuex'
import AdmAvatar from '@/views/_components/avatar/AdmAvatar'
import ShareLinkButton from './ShareLinkButton/ShareLinkButton.vue'
import TutorialVideoModal from '@/views/Guide/TutorialVideoModal.vue'
import ReferFriendDialog from '@/views/ReferFriend/ReferFriendDialog.vue'
import RateTrafftDialog from '@/views/RateTrafft/RateTrafftDialog.vue'
import Gleap from 'gleap'

export default {
  name: 'MainHeader',

  components: {
    ReferFriendDialog,
    RateTrafftDialog,
    TutorialVideoModal,
    ShareLinkButton,
    AdmAvatar,
    ReleaseNotesWrapper,
    AdmButton,
    AdmButtonGroup,
    AdmDropdown,
    AdmDropdownItem,
    AdmDropdownMenu,
    AdmFormItem,
    AdmInput,
    GuideButtonWrapper,
  },

  mixins: [
    mixinRouter,
    mixinSecurity,
    mixinLogout
  ],

  data () {
    return {
      bookingWebsitePopoverActive: false,
      isTutorialVideoVisible: false,
      showReferFriendDialog: false,
      showRateTrafftDialog: false,
    }
  },

  computed: {
    ...mapState({
      tenantInfo: state => state.billing.tenantInfo
    }),

    isOpenWebsitePopoverOpened () {
      return this.$store.getters['guide/isPopoverOpened']('bookingWebsite')
    },

    showUpgradePlan () {
      return this.hasPermission('edit_billing_plan')
        && this.tenantInfo
        && (
          this.tenantInfo.subscription === null ||
          this.tenantInfo.subscription.isAppSumo === false ||
          (this.tenantInfo.subscription.isAppSumo && this.tenantInfo.plan.name === 'trafft_tier1')
        )
        && !this.$store.getters['settings/isAgency']
    },

    isPwa () {
      return isPwa()
    },
  },

  methods: {
    goToWebsite () {
      if (this.isOpenWebsitePopoverOpened) {
        this.$store.commit('guide/closeItemPopover')
        this.$store.dispatch('settings/finishGuideStepAction', 'bookingWebsite')
        if (this.$store.getters['settings/isGuideFinished']()) {
          this.$store.dispatch('guide/openSuccessModal', { currentStep: 'final' })
        }
      }

      window.open(getBookingUrl(this.$store.getters['settings/getBookingWebsiteDomainData']()), '_blank')
    },

    getBookingUrl () {
      if (isPwa() && !isPwaOnAppleDevices() && !this.$store.getters['settings/getBookingWebsiteDomainData']().customDomain) {
        return getBookingUrl(this.$store.getters['settings/getBookingWebsiteDomainData']()) + `?redirect=${window.location.protocol + '//' + window.location.host + this.$route.path}`
      }

      return getBookingUrl(this.$store.getters['settings/getBookingWebsiteDomainData']())
    },

    copyBookingUrl () {
      copyContent('main-navigation-website-url')
      this.$message({ message: this.$t('link_copied_clipboard'), type: 'success', showClose: true })
    },

    handleHelpCommand (command) {
      if (command === 'support') {
        if (Gleap.isOpened()) {
          Gleap.close()

          return
        }
        Gleap.startConversation(true)

        return
      }

      if (command === 'watch-video-tutorials') {
        this.isTutorialVideoVisible = true
      }

      if (command === 'view-documentation') {
        window.open('https://trafft.com/docs/', '_blank')
      }

      if (command === 'suggest-feature') {
        window.open('https://features.trafft.com', '_blank')
      }
    },

    handleAccountCommand (command) {
      if (command === 'logout') {
        this.logout()
        this.$router.push({ name: 'login' })
      } else if (command === 'upgrade-plan') {
        if (this.tenantInfo && this.tenantInfo.subscription && this.tenantInfo.subscription.isAppSumo) {
          return window.open('https://appsumo.com/account/redemption/' + this.tenantInfo.subscription.appSumoItemUuid + '#change-plan', '_blank')
        }

        this.$store.commit('billing/setBillingDialogStep', 'plans')
        this.$store.commit('billing/setVisibleDialogBilling', true)
      } else if (command === 'refer-friend') {
        this.showReferFriendDialog = true
      } else if (command === 'rate-trafft') {
        this.showRateTrafftDialog = true
      } else {
        if (!this.$route.name.includes(command)) {
          this.$router.push({ name: command })
        }
      }
    },

    getVersionText () {
      return this.$t('version') + ' ' + process.env.VUE_APP_RELEASE_VERSION
    },

    handleBookingWebsitePopover () {
      this.$store.commit('guide/closeItemPopover')
    },

    toggleShareLinkModal () {
      if (this.$store.state.shareBookingForm.visible) {
        this.$store.commit('shareBookingForm/hide')

        return
      }
      this.$store.commit('shareBookingForm/show')
    },
  }
}
</script>

<style lang="scss">
// Main Header
.main-header {
  position: fixed;
  background-color: #FBFBFC;
  width: calc(100% - 72px);
  height: 60px;
  z-index: 100;
  box-shadow: 0 1px 1px rgba(0, 18, 79, 0.08);
  padding: 0 0 0 1.25rem;
  display: flex;
  align-items: center;

  // Tablet Down
  @include tablet-down {
    background-color: var(--main-navigation-color);
    height: 56px;
    width: 100%;
    padding-left: 8px;
    z-index: 999;

    // Logo
    &__logo {
      height: 40px;
      width: 40px;

      // Image
      img {
        width: 40px;
        height: 40px;
        object-fit: contain;
      }
    }
  }

  &__left {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  // Booking Website
  &__booking-website {
    margin-right: 8px;

    @include tablet-down {
      display: none;
    }

    // Popover
    &__popover {
      padding: 1rem;
      box-shadow: $box-shadow-dropdown;

      &.el-popper[x-placement^=bottom] {
        margin-top: 4px;
      }

      // Buttons
      &__buttons {
        width: 100%;
      }
    }
  }

  // Customize
  &__customize {
    margin-right: 24px;
  }

  // Button Icons
  &__icon-buttons {
    margin-left: auto;
    order: 2;
    height: 100%;
    align-items: center;
    display: flex;
    padding: 8px 16px;
    border-left: 1px solid $shade-300;
    gap: 16px;

    &--agency {
      display: none;

      @include tablet-down {
        display: flex;
      }
    }

    // Tablet Down
    @include tablet-down {
      margin-left: auto;
      border: none;
      padding: 0;
    }

    // Help
    &__help {

      // Note
      &__note {
        max-width: 224px;
        padding: 4px 0;
        border-top: 1px solid $shade-300;

        // Text
        &__text {
          padding: 0.25rem 0.5rem;
          opacity: 0.67;
          font-style: italic;
          font-size: 12px;
          line-height: 16px;
        }
      }

      // Version
      &__version {
        padding-top: 4px;
        border-top: 1px dashed $shade-300;

        // Text
        &__text {
          padding: 0.25rem 0.5rem;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    // Button
    .adm-button {
      color: $shade-800;

      i {
        @include tablet-down {
          color: $adm-white;
        }

        &.tz-cog, &.tz-question-mark-circle-outline, &.tz-activity {
          font-size: 28px;
        }
      }

      @include tablet-down {
        &:hover, &:active, &.is-active {
          background: $shade-700;
        }
      }
    }

    &__booking-website {
      &.adm-button {
        display: none;
        @include tablet-down {
          display: flex;
        }
        background-color: var(--primary-900);

        &:hover {
          background: var(--primary-800);
        }

        &:focus:not(:active), &.is-focused {
          background: var(--primary-900);
          border: 2px solid $adm-white;
          box-shadow: 0 0 0 2px var(--primary-900);
        }

        // Active
        &:active {
          background: var(--primary-1000);
        }
      }
    }

    &__share-link {
      &.adm-button {
        display: none;
        @include tablet-down {
          display: flex;
        }
        background-color: #8E42D1;

        &:hover {
          background: #7235A7;
        }

        &:focus:not(:active), &.is-focused {
          background: #7235A7;
          border: 2px solid $adm-white;
          box-shadow: 0 0 0 2px #7235A7;
        }

        // Active
        &:active {
          background: #55287D;
        }
      }
    }
  }

  // Account
  &__account {
    cursor: pointer;
    order: 4;
    height: 100%;
    align-items: center;
    display: flex;

    // Slot
    &__slot {
      display: flex;
      align-items: center;
      outline: none;
      padding: 0 1rem;
      height: 100%;
      width: 100%;
      border-left: 1px solid $shade-300;
      border-right: 1px solid transparent;

      @include tablet-down {
        border-left: 1px solid transparent;
      }

      // Hover
      &:hover {
        background: $shade-200;
        transition: background-color 0.2s ease;

        @include tablet-down {
          background: $shade-700;
        }
      }

      // Focus
      &:focus:not(:active):not(.is-active) {
        border: 1px solid var(--primary-900);
      }

      // Active
      &:active, &.is-active {
        background: $shade-250;

        @include tablet-down {
          background: $shade-700;
        }
      }

      // Name
      &__name {
        margin: 0 4px 0 12px;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
      }

      // Arrow
      &__arrow {
        path {
          fill: $shade-500;
        }
      }
    }
  }

  // Main Navigation Bar Expanded
  &.is-main-navigation-bar-expanded {
    width: calc(100% - 84px);

    @include tablet-down {
      width: 100%;
    }
  }
}
</style>
